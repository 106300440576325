<template>
	<div>
		<template v-if="!imodel.loaded"><v-progress-linear indeterminate /></template>
		<template v-else>
			<div v-show="!isEdit">
				<Search @filter="filter = $event" :defaults="defaultSearch" />
				<v-toolbar flat>
					<v-toolbar-title>{{ imodel.Title }}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn @click="onAdd" dark color="primary"><v-icon left>mdi-plus</v-icon> Нов запис</v-btn>
				</v-toolbar>
				<List
					ref="list"
					:filter="filter"
					@edit="onEdit"
					hide-delete
					hide-select
				/>
			</div>

			<div v-if="isEdit">
				<v-tabs v-model="tabs">
					<v-tab>Обща информация</v-tab>
					<v-tab v-if="id!=='new'">Шаблони за сесия</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabs">
					<v-tab-item>
						<edit-column
							:id="id"
							v-if="isEdit"
							@saveclose="onSaveClose"
							@save="onSave"
							@close="onEditCancel"
							:data.sync="utilityRow"
							fields="utility_type,city_code,utility_code,name,ord,water_decimals,contact_phone,contact_mobile,contact_email"
							:disabled-fields="id>0 ? ['utility_type','utility_code'] : []"
						/>
					</v-tab-item>
					<v-tab-item>
						<v-toolbar flat dense>
							<v-spacer/>
							<v-btn color="primary" @click="onAddTemplate">
								<v-icon left>mdi-plus</v-icon>
								Нов шаблон
							</v-btn>
						</v-toolbar>
						<v-divider />
						<List
							ref="templates"
							v-if="utilityRow.utility_code"
							:imodel="sesTplModel"
							:params="{utility_code:utilityRow.utility_code}"
							disable-pagination
							hide-select
							@edit="onEditTemplate"
						/>
						<v-dialog v-model="editTemplate" max-width="600">
							<v-card>
								<edit-column
									v-if="editTemplate"
									max-content-height="calc(90vh - 120px)"
									title="Session Template"
									:id="templateId"
									:imodel="sesTplModel"
									:constrains="{utility_code:utilityRow.utility_code}"
									hide-constrained
									@close="editTemplate = false; reloadTemplates();"
									@saveclose="editTemplate = false; reloadTemplates();"
								/>
							</v-card>
						</v-dialog>
					</v-tab-item>
				</v-tabs-items>
			</div>
		</template>
	</div>
</template>

<script>
import Model from "./Model";
import SesTplModel from "../session_templates/Model";
import List from "@/ittijs/List";
import Search from "@/ittijs/Search";
import IndexMixin from "@/ittijs/IndexMixin";
import EditColumn from "@/ittijs/EditColumn";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";

const imodel = new Model();
const sesTplModel = ITTIModelProvider.getModel(SesTplModel);

export default {

	mixins: [
		IndexMixin,
	],

	data(){
		return {
			defaultSearch: {
				rules:[],
				groups:{
					g1:{rules:{}
					}
				},
			},
			filter: {},
			imodel: imodel,
			sesTplModel: sesTplModel,
			tabs: null,
			editTemplate: false,
			templateId: null,
			utilityRow: {},
		};
	},

	components: {
		EditColumn,
		Search,
		List,
	},

	provide: function () {
		return {
			imodel,
		};
	},

	methods: {
		onEditTemplate($event){
			this.templateId = $event.id;
			this.editTemplate = true;
		},
		onAddTemplate(){
			this.templateId = 'new';
			this.editTemplate = true;
		},
		reloadTemplates(){
			this.$refs.templates.getDataFromApi();
		},
	},
};
</script>

<style>
.v-dialog.dialog-top {
	align-self: start;
}
</style>